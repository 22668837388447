import { ApiServiceBase } from '../api-service-base';
import {
  GetCollectionsArguments,
  CreateCollectionArguments,
  GetCollectionArguments,
  UpdateCollectionArguments,
  DeleteCollectionArguments,
  GetRuleGroupsArguments,
  SaveCollectionRulesDiffArguments,
} from './types';
import { Collection, RuleGroup } from '../../../../components-bl/Collections/types';
import {
  mapCreateCollectionResponse,
  mapCollectionsResponse,
  mapCollectionResponse,
} from './collectionsMapper';
import { mapRuleGroupResponse } from './ruleGroupMapper';

export class Collections extends ApiServiceBase {
  constructor() {
    super('shops');
  }

  private getCollectionsPath(shopId: number): string {
    return `${this.serviceBaseUri}/${shopId}/collections`;
  }

  async getCollections({ shopId }: GetCollectionsArguments): Promise<Collection[]> {
    const url = `${this.getCollectionsPath(shopId)}`;
    const response = await this.httpService.get({ url });

    return mapCollectionsResponse(response);
  }

  async createCollection({
    shopId,
    name,
    placement,
    ruleGroups,
  }: CreateCollectionArguments): Promise<Collection> {
    const requestData = { name, placement, ruleGroups };
    const url = `${this.getCollectionsPath(shopId)}`;
    const response = await this.httpService.post({ url, data: requestData });

    return mapCreateCollectionResponse(response);
  }

  async getCollection({ shopId, collectionId }: GetCollectionArguments): Promise<Collection> {
    const url = `${this.getCollectionsPath(shopId)}/${collectionId}`;
    const response = await this.httpService.get({ url });

    return mapCollectionResponse(response);
  }

  async updateCollection({
    shopId,
    collectionId,
    body,
  }: UpdateCollectionArguments): Promise<Collection> {
    const url = `${this.getCollectionsPath(shopId)}/${collectionId}`;
    const response = await this.httpService.patch({ url, data: body });

    return mapCollectionResponse(response);
  }

  async deleteCollection({
    shopId,
    collectionId,
  }: DeleteCollectionArguments): Promise<Collection[]> {
    const url = `${this.getCollectionsPath(shopId)}/${collectionId}`;
    const response = await this.httpService.delete({ url });

    return mapCollectionsResponse(response);
  }

  async getRuleGroups({ shopId, collectionId }: GetRuleGroupsArguments): Promise<RuleGroup[]> {
    const url = `${this.getCollectionsPath(shopId)}/${collectionId}/collection-rules/rule-groups`;
    const response = await this.httpService.get({ url });

    return mapRuleGroupResponse(response);
  }

  async saveCollectionRulesDiff({
    shopId,
    collectionId,
    body,
  }: SaveCollectionRulesDiffArguments): Promise<RuleGroup[]> {
    const url = `${this.getCollectionsPath(shopId)}/${collectionId}/collection-rules/rule-groups`;
    const response = await this.httpService.patch({ url, data: body });

    return mapRuleGroupResponse(response);
  }
}

export const collectionsService = new Collections();
