import React, { useRef } from 'react';
import {
  AvailableIcons,
  Button,
  Column,
  CustomCell,
  DeleteRowCell,
  EditableCheckBoxCell,
  EditableDropDownCell,
  EditableNumericInputCell,
  Table,
} from 'src/components-dummy';
import { useProductWeightsDraft } from './hooks';
import { RankingStrategyTableProps } from './types';
import { syteCoreValuesSet } from './constants';
import { createOptionalPortal } from '../../helpers';
import { RankingNameCell } from './components';
import { ButtonStyled } from '../../../containers/MerchandisingRules/CreateMerchandisingRuleContainer/CreateMerchandisingRuleContainer.styles';
import { RuleFormFooter } from '../../MerchandisingRules/components/MerchandisingRuleForm/RuleFormFooter/RuleFormFooter';
import './RankingStrategyTable.scss';
import { RankingStrategyType } from '../RankingStrategyType';
import { RankingType } from '../RankingStrategyType/types';
import { rankingStrategyTypeMapper } from '../RankingStrategyType/rankingTypeMapper';
import { SyteProductType } from '../../../services';

const COMPONENT_NAME = 'RankingStrategyTable';

export const RankingStrategyTable = ({
  shopId,
  selectedVariantId,
  weights,
  type,
  selectedSyteProduct,
  formHeaderElementRef,
  onDirtyChange,
  dispatch,
  dataFields,
  disabled = false,
  entityId,
  prefixId,
  hasRankingType = false,
  closeRankingStrategyDrawer,
}: RankingStrategyTableProps): JSX.Element => {
  const rowRef = useRef<HTMLElement>(null);
  const dataId = `${prefixId}_${COMPONENT_NAME}`;

  const rankingSettings = useProductWeightsDraft({
    rowRef,
    weights,
    type,
    dispatch,
    shopId,
    selectedVariantId,
    selectedSyteProduct,
    onDirtyChange,
    dataFields,
    disabled,
    entityId,
    closeRankingStrategyDrawer,
  });

  const {
    draftWeights,
    setDraftWeights,
    draftType,
    setDraftType,
    addTableRow,
    deleteTableRow,
    updateTableRow,
    getCellProps,
    onSubmit,
    isValid,
    isDirty,
    dataFieldsDropdownOptions,
    onDiscard,
    canCancel,
  } = rankingSettings;

  const loading = selectedSyteProduct && (!weights || !draftWeights);

  const formButtons = (
    <RuleFormFooter>
      <ButtonStyled
        prefixId={`${dataId}_discardRankings_button`}
        variant='tertiary'
        onClick={onDiscard}
        disabled={loading || !canCancel}
      >
        Cancel
      </ButtonStyled>
      <ButtonStyled
        prefixId={`${dataId}_saveRankings`}
        variant='primary'
        onClick={onSubmit}
        disabled={!(isValid && isDirty)}
      >
        {selectedVariantId ? 'Apply' : 'Save'}
      </ButtonStyled>
    </RuleFormFooter>
  );

  const formButtonsSection = createOptionalPortal({
    portalContent: disabled ? <></> : formButtons,
    targetContainerRef: formHeaderElementRef,
    fallback: <div className='ranking-button-wrapper'>{formButtons}</div>,
  });

  const loadingComponent = loading ? (
    <div className='ranking-strategy-table-loader'>
      <span>Loading...</span>
    </div>
  ) : null;

  const noDataComponent =
    !loading && draftWeights && draftWeights.length === 0 ? (
      <h1 className='no-rows'>No data available for this product.</h1>
    ) : null;

  const showTable = !loading && weights?.length;
  const { text } = rankingStrategyTypeMapper[draftType as RankingType] ?? {};

  const filterOutPersonalizationFromCollections = () => {
    if (draftWeights?.length && selectedSyteProduct === SyteProductType.Collections) {
      return draftWeights.filter(weight => weight.field !== 'personalization');
    }
    return draftWeights;
  };
  const filteredDraftWeights = filterOutPersonalizationFromCollections();

  return (
    <div>
      {hasRankingType && (
        <RankingStrategyType
          type={text ?? RankingType.WeightedRanking}
          onChange={setDraftType}
          className='ranking-strategy-type'
        />
      )}
      <div className='ranking-table-wrapper'>
        <div className='title'>
          {!dataFieldsDropdownOptions.length && showTable && (
            <span className='no-data-fields'>
              The shop has no data fields. Please, contact administrator
            </span>
          )}
          {disabled || loading || !selectedSyteProduct ? (
            <></>
          ) : (
            <Button
              prefixId={`${dataId}_addWeight`}
              variant='secondary'
              onClick={() => addTableRow()}
              startIcon={AvailableIcons.Add}
              className='add-new-row-button'
            >
              Add a parameter
            </Button>
          )}
        </div>

        {loadingComponent}
        {noDataComponent}

        {showTable && filteredDraftWeights?.length ? (
          <Table
            data={filteredDraftWeights}
            className='table-ranking-strategy'
            updateData={updateTableRow}
            rowRef={rowRef}
            setDataState={setDraftWeights}
            disableSortBy={false}
            enableResizing
            getCellProps={getCellProps}
          >
            <Column
              Header=''
              id='enabled'
              accessor='enabled'
              cellProps={{ className: 'enabled', prefixId: `${dataId}_enabled` }}
              className='enabled-checkbox'
              disableResizing
              width={0}
              Cell={EditableCheckBoxCell}
            />
            <Column
              Header='Name'
              id='name'
              accessor='name'
              cellProps={{ className: 'name', prefixId: `${dataId}_name` }}
              width={150}
              minWidth={150}
              Cell={RankingNameCell}
            />
            <Column
              Header='Data field source'
              id='field'
              accessor='field'
              cellProps={{
                className: 'field',
                rowRef,
                prefixId: `${dataId}_dataField`,
              }}
              width={150}
              minWidth={150}
              Cell={EditableDropDownCell}
            />

            <Column
              Header='Order'
              id='order'
              accessor='order'
              Cell={EditableDropDownCell}
              cellProps={{ className: 'order', rowRef, prefixId: `${dataId}_order` }}
              width={150}
              minWidth={150}
            />
            <Column
              Header='Weight'
              id='weight'
              accessor='weight'
              Cell={EditableNumericInputCell}
              cellProps={{ className: 'weight', min: 0, max: 100, prefixId: `${dataId}_weight` }}
              className='weight'
              width={100}
            />
            {(!disabled && (
              <Column
                Header=''
                id='delete'
                accessor='delete'
                cellProps={{ className: 'delete', prefixId: `${dataId}_delete` }}
                className='delete'
                width={30}
              >
                <CustomCell>
                  {(value: any) => {
                    const newValue = { ...value };
                    if (syteCoreValuesSet.has(value?.row?.original?.field)) {
                      return <></>;
                    }
                    newValue.deleteTableRow = deleteTableRow;
                    return <DeleteRowCell {...newValue} />;
                  }}
                </CustomCell>
              </Column>
            )) || <></>}
          </Table>
        ) : null}
      </div>

      {formButtonsSection}
    </div>
  );
};
