import React from 'react';
import { ProductImage } from './ProductImage';
import { ProductDescription } from './ProductDescription';
import { useDragAndDrop } from '../../../../../hooks/useDragAndDrop';
import { useGetVariant } from './helpers/use-get-variant';
import { AdData } from '../../../../../services';
import { DataFieldToDisplay } from '../../../types/data-field-to-display';
import { SpecialDataFieldToDisplay } from '../../../types/special-data-field-to-display';
import { DraggableProductTileContainer } from './ProductTile.styles';

const PRODUCT_TILE_DRAG_DROP_TYPE = 'PRODUCT_TILE_DRAG_DROP_TYPE';

interface DraggableProductTileProps {
  offer: AdData;
  index: number;
  dataFieldsToDisplay: DataFieldToDisplay[];
  specialDataFieldsToDisplay: SpecialDataFieldToDisplay[];
  isOpen: boolean;
  toggleAllItems: VoidFunction;
  onDrag: (sourceIndex: number, targetIndex: number) => void;
  onDrop?: () => void;
  openProductDetailsModal: (sku?: string) => void;
}

export function DraggableProductTile({
  offer,
  index,
  dataFieldsToDisplay,
  specialDataFieldsToDisplay,
  isOpen,
  toggleAllItems,
  onDrag,
  onDrop,
  openProductDetailsModal,
}: DraggableProductTileProps): JSX.Element {
  const { dragElementRef, isDragging } = useDragAndDrop<HTMLDivElement>({
    acceptKey: PRODUCT_TILE_DRAG_DROP_TYPE,
    index,
    onDrag,
    onDrop,
  });

  const {
    variant,
    variants,
    selectedColorIndex,
    selectedSizeIndex,
    onColorVariantClick,
    onSizeVariantClick,
  } = useGetVariant(offer);

  return (
    <DraggableProductTileContainer
      ref={dragElementRef}
      isDragging={isDragging}
      onClick={() => openProductDetailsModal(variant?.sku)}
    >
      <ProductImage imageUrl={variant.imageUrl} index={index + 1} />
      <ProductDescription
        offer={variant}
        dataFieldsToDisplay={dataFieldsToDisplay}
        specialDataFieldsToDisplay={specialDataFieldsToDisplay}
        isOpen={isOpen}
        toggleAllItems={toggleAllItems}
        variants={variants}
        selectedColorIndex={selectedColorIndex}
        onColorVariantClick={onColorVariantClick}
        selectedSizeIndex={selectedSizeIndex}
        onSizeVariantClick={onSizeVariantClick}
      />
    </DraggableProductTileContainer>
  );
}
