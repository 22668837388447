import React, { useEffect, useMemo, useState } from 'react';
import Joi from 'joi';
import { ProductCard } from '../ProductCard';
import { useAutoCompleteFetchState } from '../AutoComplete';
import { SuggestedOffer } from '../../types';
import {
  Typography,
  TypographyType,
  TypographyVariant,
  AutoCompleteSingleValue,
} from '../../../../components-dummy';
import { AddCustomImage } from './AddCustomImage';
import { CustomImageForm } from './CustomImageForm';
import { FormGroup } from '../Form';
import { useDiscoveryButtonSettings } from '../../hooks';
import { StateController } from '../SideMenu/ShopperExperience/useStateControllerRef';
import { PreviewFeature } from '../../../../services';
import { PermittedRouteMap } from '../../../../app-routes';
import { useSyncParamsWithState } from '../../helpers/use-sync-params-with-state';
import { useGetQueryParam } from '../../../../hooks/use-get-query-param';
import { useAddParamsToRoute } from '../../hooks/useAddParamsToRoute';

const imageURLSchema = Joi.string()
  .optional()
  .allow('')
  .uri({ scheme: ['http', 'https'] })
  .error(new Error('Invalid image URL'));

interface SaveState {
  autocompleteValue: string;
  customImageURL: string;
  showCustomImage: boolean;
}

interface Props {
  shopId: number;
  offer: SuggestedOffer | null;
  onOfferChange: (offer: SuggestedOffer | null) => void;
  stateController: StateController<SaveState>;
  selectedExperience?: PreviewFeature;
  permittedRouteMap: PermittedRouteMap;
  openProductDetailsModal: (sku?: string) => void;
}

export function DiscoveryButtonSettings({
  shopId,
  offer,
  onOfferChange,
  stateController: { value, setValue },
  selectedExperience,
  permittedRouteMap,
  openProductDetailsModal,
}: Props): JSX.Element {
  const imageUrlParam = useGetQueryParam('imageUrl');

  const [showCustomImage, setShowCustomImage] = useState<boolean>(
    value?.showCustomImage || Boolean(imageUrlParam) || false
  );
  const [customImageURL, setCustomImageURL] = useState<string>(value?.customImageURL || '');
  const { reset, ...autoCompleteProps } = useAutoCompleteFetchState({
    shopId,
    onOfferChange,
    selectedExperience,
    initialValue: value?.autocompleteValue,
    permittedRouteMap,
  });

  useSyncParamsWithState({ paramName: 'imageUrl', setState: setCustomImageURL });

  const { updateImageURL } = useDiscoveryButtonSettings(shopId);

  const removeImageUrl = () => setCustomImageURL('');
  const toggleCustomImage = () => setShowCustomImage(p => !p);
  const resetInputData = () => {
    setShowCustomImage(false);
    setCustomImageURL('');
    reset();
  };

  const { error } = useMemo(() => imageURLSchema.validate(customImageURL), [customImageURL]);

  const hasValidInputImage = !error?.message && !!customImageURL;
  const offersImage = offer?.ctlImageUrl || offer?.imageUrl || '';
  const selectedImage = offer && !hasValidInputImage ? offersImage : customImageURL;
  const offersSKU = offer && !hasValidInputImage ? offer.sku : '';
  const showProductCard = !!offer || hasValidInputImage;

  const suggestedOffer = {
    ...offer,
    sku: offersSKU,
    imageUrl: selectedImage,
  };

  const { addParamsToRoute } = useAddParamsToRoute({
    shopId,
    route: permittedRouteMap?.visualEditor,
  });

  useEffect(() => {
    updateImageURL?.(customImageURL.trim());
    addParamsToRoute({
      shopperExperience: selectedExperience,
      search: value?.autocompleteValue,
      imageUrl: encodeURIComponent(customImageURL.trim()),
    });
  }, [customImageURL, value, selectedExperience]);

  useEffect(() => {
    setValue({
      showCustomImage,
      customImageURL,
      autocompleteValue: autoCompleteProps.selectedValue,
    });
  }, [showCustomImage, customImageURL, autoCompleteProps.selectedValue]);

  return (
    <>
      <FormGroup>
        <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
          When shopper is viewing product
        </Typography>
        <AutoCompleteSingleValue
          closeOnSingleMatch
          placeholder='Search by description, brand, sku...'
          {...autoCompleteProps}
        />
        {!showCustomImage ? (
          <AddCustomImage onClick={toggleCustomImage} />
        ) : (
          <CustomImageForm
            value={customImageURL}
            onChange={setCustomImageURL}
            onDelete={removeImageUrl}
            error={error?.message}
          />
        )}
      </FormGroup>
      {showProductCard && (
        <ProductCard
          key={offersSKU}
          offer={suggestedOffer}
          onClose={resetInputData}
          openProductDetailsModal={openProductDetailsModal}
        />
      )}
    </>
  );
}
