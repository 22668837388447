import React from 'react';
import { Box } from 'src/components-dummy/Box';
import { Skeleton } from 'src/components-dummy/Skeleton';
import { SkeletonVerticalBlocksWithTitle } from 'src/components-dummy/Skeleton/skeletonCompositions';

export const ProductDetailsSkeleton = (): JSX.Element => {
  return (
    <>
      <Box sx={{ padding: '16px 24px' }}>
        <Skeleton width='20%' />
        <Skeleton width='40%' sx={{ marginTop: '8px' }} />
      </Box>
      <Box sx={{ display: 'flex', height: '660px' }}>
        <SkeletonVerticalBlocksWithTitle
          itemProps={{ height: 500, width: '90%' }}
          sx={{ padding: '16px 0 16px 24px', flex: 5 }}
        />
        <SkeletonVerticalBlocksWithTitle
          spacing={2}
          sx={{ padding: '16px 24px 16px 0', flex: 5 }}
        />
      </Box>
    </>
  );
};
