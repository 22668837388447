import React from 'react';
import { ProductTileContainer } from './ProductTile.styles';
import { ProductImage } from './ProductImage';
import { ProductDescription } from './ProductDescription';
import { useGetVariant } from './helpers/use-get-variant';
import { AdData } from '../../../../../services';
import { DataFieldToDisplay } from '../../../types/data-field-to-display';
import { SpecialDataFieldToDisplay } from '../../../types/special-data-field-to-display';

interface ProductTileProps {
  offer: AdData;
  index: number;
  dataFieldsToDisplay: DataFieldToDisplay[];
  specialDataFieldsToDisplay: SpecialDataFieldToDisplay[];
  isOpen: boolean;
  toggleAllItems: VoidFunction;
  openProductDetailsModal: (sku?: string) => void;
}

export function ProductTile({
  offer,
  index,
  dataFieldsToDisplay,
  specialDataFieldsToDisplay,
  isOpen,
  toggleAllItems,
  openProductDetailsModal,
}: ProductTileProps): JSX.Element {
  const {
    variant,
    variants,
    selectedColorIndex,
    selectedSizeIndex,
    onColorVariantClick,
    onSizeVariantClick,
  } = useGetVariant(offer);

  return (
    <ProductTileContainer onClick={() => openProductDetailsModal(variant?.sku)}>
      <ProductImage
        imageUrl={variant.imageUrl}
        index={offer.index ?? index}
        isPinned={offer.isPinned}
      />
      <ProductDescription
        offer={variant}
        dataFieldsToDisplay={dataFieldsToDisplay}
        specialDataFieldsToDisplay={specialDataFieldsToDisplay}
        isOpen={isOpen}
        toggleAllItems={toggleAllItems}
        variants={variants}
        selectedColorIndex={selectedColorIndex}
        onColorVariantClick={onColorVariantClick}
        selectedSizeIndex={selectedSizeIndex}
        onSizeVariantClick={onSizeVariantClick}
      />
    </ProductTileContainer>
  );
}
