import { CustomInspirationsGallery, CustomInspirationsGalleryImage } from 'src/services';
import { CustomInspirationGalleryDraft } from './types';

export class GalleryMapper {
  static getUniqueSkusFromImages(images: CustomInspirationsGalleryImage[]): string[] {
    const allUniqueSkus = new Set<string>();

    for (const image of images) {
      for (const tag of image.tags) {
        allUniqueSkus.add(tag.sku);
      }

      for (const tag of image.aiSuggestedTags) {
        allUniqueSkus.add(tag.sku);
      }
    }

    return [...allUniqueSkus];
  }

  static mapGalleryToDraft(
    gallery?: CustomInspirationsGallery
  ): CustomInspirationGalleryDraft | undefined {
    return gallery
      ? {
          displayTitle: gallery.displayTitle,
          layout: gallery.layout,
          images: gallery.images,
          placement: gallery.placement,
          showSimilarProductWhenUnavailable: gallery.showSimilarProductWhenUnavailable,
        }
      : undefined;
  }
}
