import { useCallback, useState } from 'react';
import { AutoCompleteOption, useDebounce } from 'src/components-dummy';
import { Dispatch } from 'src/components-bl/types';
import { MAX_POSITION_VALUE } from '../constants';
import { skuAutoSuggestionActions } from './SkuAutoSuggestion.actions';
import { SkuConfiguration } from 'src/components-bl/ProductCard/ProductCard';

const ADDITIONAL_DATA_FIELDS = ['title', 'brand', 'parent_sku', 'sku'];
const FIELDS_TO_RETURN = ['imageUrl', 'gender', 'categories', 'currency', 'originalPrice', 'price'];

type FetchOptionsArguments = {
  searchTerm: string;
};

type FetchProductsArguments = {
  searchTerms: string[];
  primaryDataField?: string;
};

export const useFetchProducts = ({
  dispatch,
  shopId,
  initialLoading = false,
}: {
  dispatch: Dispatch;
  initialLoading?: boolean;
  shopId: number;
}): {
  options: AutoCompleteOption<string, SkuConfiguration>[];
  loading: boolean;
  uniqueByField: keyof SkuConfiguration | undefined;
  fetchDataFieldOptionsDebounced: (args: FetchOptionsArguments) => void;
  fetchProducts: (args: FetchProductsArguments) => void;
  fetchProductsDebounced: (args: FetchProductsArguments) => void;
  resetOptions: () => void;
} => {
  const [uniqueByField, setUniqueByField] = useState<keyof SkuConfiguration | undefined>(undefined);

  const [options, setOptions] = useState<AutoCompleteOption<string, SkuConfiguration>[]>([]);

  const [loading, setIsLoading] = useState(initialLoading);

  const fetchDataFieldOptionsDebounced = useDebounce(
    async ({ searchTerm }: FetchOptionsArguments) => {
      if (searchTerm && searchTerm.length < 2) {
        setOptions([]);

        setIsLoading(false);
        return;
      }

      setIsLoading(true);

      try {
        const {
          values: dataFieldOptions,
          uniqueByField: dataFieldUniqueBy,
          // eslint-disable-next-line no-await-in-loop
        } = (await (
          dispatch(
            skuAutoSuggestionActions.getValues({
              shopId,
              additionalDataFields: ADDITIONAL_DATA_FIELDS,
              searchTerm,
              limit: 50,
              fieldsToReturn: FIELDS_TO_RETURN,
            })
          ) as any
        ).unwrap()) as {
          values: SkuConfiguration[];
          uniqueByField: keyof SkuConfiguration;
        };

        setUniqueByField(dataFieldUniqueBy);

        setOptions(previousOptions => {
          const newOptions = [
            ...previousOptions,
            ...dataFieldOptions.map(data => {
              return {
                value: data[dataFieldUniqueBy],
                title: data[dataFieldUniqueBy],
                data,
              };
            }),
          ];

          return newOptions;
        });
      } catch (error) {
        console.error(error);
        setOptions([]);
      }

      setIsLoading(false);
    },
    500
  );

  const fetchProducts = useCallback(
    async ({ searchTerms, primaryDataField }: FetchProductsArguments) => {
      if (searchTerms.length === 0) {
        setIsLoading(false);
        return;
      }

      setIsLoading(true);

      try {
        const {
          products: dataFieldOptions,
          uniqueByField: dataFieldUniqueBy,
          // eslint-disable-next-line no-await-in-loop
        } = (await (
          dispatch(
            skuAutoSuggestionActions.getProducts({
              shopId,
              searchTerms,
              limit: MAX_POSITION_VALUE,
              fieldsToReturn: [...FIELDS_TO_RETURN, ...ADDITIONAL_DATA_FIELDS],
              primaryDataField,
            })
          ) as any
        ).unwrap()) as {
          products: SkuConfiguration[];
          uniqueByField: keyof SkuConfiguration;
        };

        setUniqueByField(dataFieldUniqueBy);

        setOptions(previousOptions => {
          const newOptions = [
            ...previousOptions,
            ...dataFieldOptions.map(data => {
              return {
                value: data[dataFieldUniqueBy],
                title: data[dataFieldUniqueBy],
                data,
              };
            }),
          ];

          return newOptions;
        });
      } catch (error) {
        console.error(error);
        setOptions([]);
      }

      setIsLoading(false);
    },
    [shopId]
  );

  const fetchProductsDebounced = useDebounce(fetchProducts, 500);

  const resetOptions = useCallback(() => {
    setOptions([]);
  }, [setOptions]);

  return {
    fetchProducts,
    loading,
    options,
    fetchDataFieldOptionsDebounced,
    fetchProductsDebounced,
    uniqueByField,
    resetOptions,
  };
};
