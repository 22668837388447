export enum VariantFeature {
  RankingStrategy = 'rankingStrategy',
  MerchandisingRules = 'merchandisingRules',
  ShopTheLookSettings = 'shopTheLookSettings',
  BoughtTogetherSettings = 'boughtTogetherSettings',
  RecentlyViewedSettings = 'recentlyViewedSettings',
  PersonalizationSettings = 'personalizationSettings',
  RecEnginesGeneralSettings = 'recEnginesGeneralSettings',
  SimilarItemsSettings = 'similarItemsSettings',
  EnableUiTest = 'enableUiTest',
  UseKnn = 'useKnn',
  DiscoveryBannerSettings = 'discoveryBannerSettings',
  ResultsModalSettings = 'resultsModalSettings',
}

export const featureTranslationMap = {
  [VariantFeature.RankingStrategy]: 'Ranking Strategy',
  [VariantFeature.MerchandisingRules]: 'Merchandising Rules',
  [VariantFeature.ShopTheLookSettings]: 'Recommendation Engines - Shop The Look',
  [VariantFeature.BoughtTogetherSettings]: 'Recommendation Engines - Bought Together',
  [VariantFeature.RecentlyViewedSettings]: 'Recommendation Engines - Recently Viewed',
  [VariantFeature.PersonalizationSettings]: 'Recommendation Engines - Personalization',
  [VariantFeature.RecEnginesGeneralSettings]: 'Recommendation Engines - General Settings',
  [VariantFeature.SimilarItemsSettings]: 'Recommendation Engines - Similar Items',
  [VariantFeature.DiscoveryBannerSettings]: 'Visual Search - Discovery Banner',
  [VariantFeature.EnableUiTest]: 'Ui Test',
  [VariantFeature.UseKnn]: 'Use OpenSearch KNN',
  [VariantFeature.ResultsModalSettings]: 'Results Modal Settings',
};
