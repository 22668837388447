import { useCallback, useMemo } from 'react';
import { defaultRuleSettings } from 'src/components-bl/MerchandisingRules/components/constants';
import { allConditionTypeDropDownValues } from 'src/components-bl/MerchandisingRules/components/DataFieldsCondition/DataFieldsCondition';
import { getDataFieldConditionOperators } from 'src/components-bl/MerchandisingRules/components/useDataFieldsLookupTable';
import { Operator, UseRulesDropdownProps, UseRulesDropdownReturnType } from './types';
import { MerchandisingRuleSubType } from '../../../../../services/src/service/types/shops';
import { getFieldFromDataFields } from '../helpers/getFieldFromDataFields';

export const useOperatorDropdown = ({
  data,
  onChange,
  dataFields,
}: UseRulesDropdownProps): UseRulesDropdownReturnType => {
  const defaultConditionTypes = defaultRuleSettings.subRulesConditionTypes.filter(
    type => type !== MerchandisingRuleSubType.EqualsSource
  );
  const field = getFieldFromDataFields(data.field, dataFields);
  const conditionTypes = getDataFieldConditionOperators({
    types: field?.types || [],
  });

  const relevantConditionTypesSet = useMemo(() => {
    return new Set(conditionTypes.filter(type => defaultConditionTypes.includes(type)) || []);
  }, [defaultConditionTypes, conditionTypes]);

  const conditionDropDownValues = useMemo(() => {
    return allConditionTypeDropDownValues.filter(item => {
      return relevantConditionTypesSet.has(item.value);
    });
  }, [relevantConditionTypesSet]);

  const onOperatorChange = useCallback(
    (selectedOperator: Operator) => {
      onChange({ ...data, values: [], operator: selectedOperator });
    },
    [conditionTypes, onChange]
  );

  return {
    conditionDropDownValues,
    onOperatorChange,
  };
};
