import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import { filterSetsActions } from 'src/containers';
import { filtersContainerActions } from 'src/containers/Filters';
import {
  BoughtTogetherActionTypes,
  BoughtTogetherSettingsAction,
  PersonalizationActionTypes,
  PersonalizationSettingsAction,
  RankingStrategyAction,
  RankingStrategyActionTypes,
} from 'src/components-bl';
import { merchandisingRulesListContainerActions } from 'src/containers/MerchandisingRules/MerchandisingRulesListContainer';
import { createMerchandisingRuleContainerActions } from 'src/containers/MerchandisingRules/CreateMerchandisingRuleContainer';
import { editMerchandisingRuleContainerActions } from 'src/containers/MerchandisingRules/EditMerchandisingRuleContainer';
import { merchandisingRulesManagementActions } from 'src/components-bl/MerchandisingRules/components/MerchandisingRulesManagement/MerchandisingRulesManagement.actions';
import { useDataFieldsWSActions, dataFieldsTableFormActions } from '../components-bl';
import { DataFieldsReducerState } from '../app-state-types';
import { editCollectionRulesContainerActions } from '../components-bl/Collections/EditCollectionRules/CollectionRules/CollectionRules/EditCollectionRulesContainerActions';
import { collectionDetailsActions } from '../components-bl/Collections/EditCollectionRules/CollectionDetails/CollectionDetailsActions';

type LegacyAction =
  | BoughtTogetherSettingsAction
  | PersonalizationSettingsAction
  | RankingStrategyAction;

const initialState: DataFieldsReducerState = {
  dataFields: undefined,
};

export const dataFieldsReducer = createReducer(initialState, builder => {
  builder
    .addCase(useDataFieldsWSActions.dataFieldsUpdated, (state, action) => {
      return {
        ...state,
        dataFields: action.payload.dataFields,
      };
    })
    .addCase(merchandisingRulesListContainerActions.getDataFields.fulfilled, (state, action) => {
      return { ...state, dataFields: action.payload.dataFields };
    })
    .addCase(createMerchandisingRuleContainerActions.getDataFields.fulfilled, (state, action) => {
      return { ...state, dataFields: action.payload.dataFields };
    })
    .addCase(editMerchandisingRuleContainerActions.getRule.fulfilled, (state, action) => {
      return { ...state, dataFields: action.payload.dataFields };
    })
    .addCase(merchandisingRulesManagementActions.getDataFields.fulfilled, (state, action) => {
      return { ...state, dataFields: action.payload.dataFields };
    })
    .addCase(dataFieldsTableFormActions.updateDataFields.fulfilled, (state, action) => {
      return { ...state, dataFields: action.payload.dataFields };
    })
    .addCase(editCollectionRulesContainerActions.getDataFields.fulfilled, (state, action) => {
      return { ...state, dataFields: action.payload.dataFields };
    })
    .addCase(collectionDetailsActions.getDataFields.fulfilled, (state, action) => {
      return { ...state, dataFields: action.payload.dataFields };
    })
    .addMatcher(
      isAnyOf(
        dataFieldsTableFormActions.getDataFields.fulfilled,
        filterSetsActions.getDataFields.fulfilled,
        filtersContainerActions.getDataFields.fulfilled
      ),
      (state, action) => {
        return { ...state, dataFields: action.payload.dataFields };
      }
    )
    .addMatcher(
      isAnyOf(
        dataFieldsTableFormActions.getDataFields.pending,
        filterSetsActions.getDataFields.pending,
        filtersContainerActions.getDataFields.pending
      ),
      state => {
        return { ...state, dataFields: initialState.dataFields };
      }
    )
    .addDefaultCase((state, toolkitAction) => {
      const action = toolkitAction as LegacyAction;
      switch (action.type) {
        case BoughtTogetherActionTypes.GetDataFieldsSuccess:
        case PersonalizationActionTypes.GetDataFieldsSuccess:
        case RankingStrategyActionTypes.GetDataFieldsSuccess:
          return {
            ...state,
            dataFields: action.payload.dataFields,
          };
        default:
          return state;
      }
    });
});
