import React, { useCallback, useState } from 'react';
import { Row } from 'react-table';
import { TableV2, TableV2InstanceProps } from 'src/components-dummy';
import { tableColumnsClient } from './CatalogFeedUploadLogList.config';
import { CatalogFeedUploadLogTableBodyRow } from './types';
import { TableBodyRowCellStyled } from './CatalogFeedUploadLogTableRow.styles';
import { RowActionsMenu } from './CatalogFeedUploadRowActions/RowActionsMenu';

interface CatalogFeedUploadLogTableProps {
  row: Row<CatalogFeedUploadLogTableBodyRow>;
  isResizingColumn?: boolean;
  onDownloadFile: ({ jobId }: { jobId: string }) => void;
  navigateToFeedErrorReport: (startDateInMilliseconds: string) => void;
}

export const CatalogFeedUploadLogTableRow = ({
  row,
  navigateToFeedErrorReport,
  isResizingColumn,
  onDownloadFile,
}: CatalogFeedUploadLogTableProps): JSX.Element => {
  const [menuAnchorElement, setMenuAnchorElement] = useState<HTMLElement | null>(null);

  const onMenuClicked = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setMenuAnchorElement(event.currentTarget);
    },
    [setMenuAnchorElement]
  );

  const onMenuClose = useCallback(() => {
    setMenuAnchorElement(null);
  }, [setMenuAnchorElement]);

  const onDetailsClick = useCallback(() => {
    onMenuClose();
    navigateToFeedErrorReport(row.original.originalData.jobId);
  }, [navigateToFeedErrorReport, onMenuClose, row.original.originalData.jobId]);

  const onDownloadFeedFileClick = useCallback(() => {
    onMenuClose();
    onDownloadFile({ jobId: row.original.originalData.jobId });
  }, [onDownloadFile, onMenuClose, row.original.originalData.jobId]);

  const renderCells = (
    propName: keyof CatalogFeedUploadLogTableBodyRow,
    cell: TableV2InstanceProps<CatalogFeedUploadLogTableBodyRow>['rows'][0]['cells'][0]
  ) => {
    if (propName === tableColumnsClient.rowActions.accessor) {
      return (
        <RowActionsMenu
          menuAnchorElement={menuAnchorElement}
          onMenuClicked={onMenuClicked}
          onMenuClose={onMenuClose}
          onDetailsClick={row.original.originalData.hasSkuErrorsData ? onDetailsClick : undefined}
          onDownloadFeedFileClick={
            row.original.originalData.hasFeedFileUrl ? onDownloadFeedFileClick : undefined
          }
        />
      );
    }

    return (
      <TableV2.BodyRowCellText key={`${propName}-${isResizingColumn}`}>
        {cell.value}
      </TableV2.BodyRowCellText>
    );
  };

  return (
    <>
      <TableV2.BodyRow
        {...row.getRowProps()}
        key={`${row.original.originalData.jobId}_${
          row.original.originalData.status
        }_${row.original.originalData.startDate.getTime()}_${row.original.originalData.endDate.getTime()}`}
      >
        {row.cells.map(cell => {
          const propName = cell.column.id;

          return (
            <TableBodyRowCellStyled
              {...cell.getCellProps()}
              key={`${cell.column.id}-${cell.value}`}
              isResizable
            >
              {renderCells(propName as keyof CatalogFeedUploadLogTableBodyRow, cell)}
            </TableBodyRowCellStyled>
          );
        })}
      </TableV2.BodyRow>
    </>
  );
};
