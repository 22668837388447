import { useCallback, useMemo } from 'react';
import { useFetchProducts } from 'src/components-bl/MerchandisingRules/components/MerchandisingRuleForm/components/RuleFormConditionsSection/PinToPosition/SkuAutoSuggestion/useFetchProducts';
import { chunk } from 'lodash';

import { Dispatch } from 'src/components-bl/types';
import { MAX_POSITION_VALUE } from 'src/components-bl/MerchandisingRules/components/MerchandisingRuleForm/components/RuleFormConditionsSection/PinToPosition/constants';
import { SkuConfiguration } from 'src/components-bl/ProductCard/ProductCard';

export const useFetchProductsInBulk = ({
  dispatch,
  shopId,
  initialLoading = false,
}: {
  dispatch: Dispatch;
  initialLoading?: boolean;
  shopId: number;
}): {
  productInformationLookup: Record<string, SkuConfiguration>;
  fetchProductsInBulk: (skus: string[]) => void;
  resetOptions: () => void;
  loading: boolean;
} => {
  const { options, loading, uniqueByField, resetOptions, fetchProducts } = useFetchProducts({
    dispatch,
    shopId,
    initialLoading,
  });

  const productInformationLookup = useMemo(() => {
    if (!uniqueByField) {
      return {};
    }

    return options.reduce(
      (accumulator, current) => {
        const key = current.data?.[uniqueByField];

        if (key) {
          accumulator[key] = current.data;
        }

        return accumulator;
      },
      {} as Record<string, SkuConfiguration>
    );
  }, [options, uniqueByField]);

  const fetchProductsInBulk = useCallback(
    (skus: string[]) => {
      for (const searchTerms of chunk(skus, MAX_POSITION_VALUE)) {
        fetchProducts({ searchTerms, primaryDataField: 'sku' });
      }
    },
    [fetchProducts]
  );

  return { productInformationLookup, fetchProductsInBulk, loading, resetOptions };
};
