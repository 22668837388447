import React, { useCallback, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { generatePath } from 'react-router';
import { Dispatch } from 'src/components-bl/types';
import {
  AvailableIcons,
  Button,
  Icon,
  Typography,
  TypographyType,
  TypographyVariant,
  Link,
  Select,
  SelectType,
  MenuItem,
  Switch,
  EllipsisWithTooltip,
} from 'src/components-dummy';
import { ILexiconMetadata, ILexiconTagsPaginationAPI } from 'src/services';
import { UserRoles } from 'src/services/src/service/types/users';
import { LexiconTagType } from 'src/services/src/service/lexicon/types';
import {
  BulletStyled,
  FlagIcon,
  LexiconPageHeaderActionContainerStyled,
  LexiconPageHeaderBackIconStyled,
  LexiconPageHeaderStyled,
  LexiconPageHeaderSubTitleRowStyled,
  LexiconPageHeaderTitleContainerStyled,
  LexiconPageHeaderTitleLocale,
  LexiconPageHeaderTitleRowStyled,
  LexiconPageMainHeaderTitle,
  ActionsSwitchStyled,
  MenuItemWithIconStyled,
  EllipsisWithTooltipStyled,
} from './LexiconPageHeader.styles';

import { lexiconPageHeaderActions } from './LexiconPageHeader.actions';

export interface LexiconPageHeaderProps {
  dispatch: Dispatch;
  shopId: number;
  locale: string;
  lexiconMetadata: ILexiconMetadata | undefined;
  showApplyAll: boolean;
  createThematicTagRoute?: string;
  createLexiconRuleRoute?: string;
  lexiconsRoute?: string;
  importLexiconRoute?: string;
  exportLexiconRoute?: string;
  refetchLexiconTags?: () => Promise<ILexiconTagsPaginationAPI>;
  displayName?: string;
  iconName?: string;
  userRole?: UserRoles;
  tagType: LexiconTagType;
}

function dateToText(date: Date | undefined): string {
  return date ? format(date, 'LLL d yyyy') : '';
}

export const LexiconPageHeader = ({
  shopId,
  locale,
  dispatch,
  lexiconMetadata,
  showApplyAll,
  lexiconsRoute,
  createThematicTagRoute,
  createLexiconRuleRoute,
  importLexiconRoute,
  exportLexiconRoute,
  refetchLexiconTags,
  displayName,
  iconName,
  userRole,
  tagType,
}: LexiconPageHeaderProps): JSX.Element => {
  const [isInProgress, setIsInProgress] = useState(false);
  const onShowApplyOnChange = useCallback(
    (checked: boolean) => {
      dispatch(lexiconPageHeaderActions.updateShowApplyAll({ shouldShow: checked }));
    },
    [dispatch]
  );

  const onPublishLexiconClick = useCallback(async () => {
    if (!lexiconMetadata?.isPublished) {
      setIsInProgress(true);
      await dispatch(lexiconPageHeaderActions.publishLexicon({ shopId, locale }));
      refetchLexiconTags?.();

      setIsInProgress(false);
    }
  }, [shopId, locale, lexiconMetadata?.isPublished, refetchLexiconTags, setIsInProgress, dispatch]);

  const onNavigateToListPage = useCallback(() => {
    if (lexiconsRoute && shopId) {
      dispatch(
        lexiconPageHeaderActions.navigateTo({
          navigateTo: generatePath(lexiconsRoute, { shopId }),
        })
      );
    }
  }, [dispatch, shopId, lexiconsRoute]);

  const onNavigateToCreateThematicTagPage = useCallback(() => {
    if (createThematicTagRoute && shopId && locale) {
      dispatch(
        lexiconPageHeaderActions.navigateTo({
          navigateTo: generatePath(createThematicTagRoute, { shopId, locale }),
        })
      );
    }
  }, [dispatch, shopId, createThematicTagRoute, locale]);

  const onNavigateToCreateLexiconRulePage = useCallback(() => {
    if (createLexiconRuleRoute && shopId && locale) {
      dispatch(
        lexiconPageHeaderActions.navigateTo({
          navigateTo: generatePath(createLexiconRuleRoute, { shopId, locale }),
        })
      );
    }
  }, [dispatch, shopId, createThematicTagRoute, locale]);

  const onNavigateToImportLexiconPage = useCallback(() => {
    if (importLexiconRoute && shopId && locale) {
      dispatch(
        lexiconPageHeaderActions.navigateTo({
          navigateTo: generatePath(importLexiconRoute, { shopId, locale, tagType }),
        })
      );
    }
  }, [dispatch, shopId, importLexiconRoute, locale, tagType]);

  const onNavigateToExportLexiconPage = useCallback(() => {
    if (exportLexiconRoute && shopId && locale) {
      dispatch(
        lexiconPageHeaderActions.navigateTo({
          navigateTo: generatePath(exportLexiconRoute, { shopId, locale, tagType }),
        })
      );
    }
  }, [dispatch, shopId, exportLexiconRoute, locale, tagType]);

  const fetchMetadata = useCallback(() => {
    dispatch(lexiconPageHeaderActions.getLexiconMetadata({ shopId, locale }));
  }, [dispatch, shopId, locale]);

  useEffect(() => {
    fetchMetadata();
    return () => {
      dispatch(lexiconPageHeaderActions.resetLexiconMetadata());
    };
  }, [dispatch]);

  const shouldShowPublished = lexiconMetadata?.publishedBy && lexiconMetadata?.publishedAt;

  const shouldShowUpdate =
    !shouldShowPublished && lexiconMetadata?.lastUpdatedBy && lexiconMetadata?.updatedAt;

  const lastPublishedText = `Last published by ${lexiconMetadata?.publishedBy}`;
  const lastPublishedDateText = `at ${dateToText(lexiconMetadata?.publishedAt)}`;
  const publishedRow = shouldShowPublished ? (
    <>
      <EllipsisWithTooltipStyled tooltipText={lastPublishedText}>
        <Typography type={TypographyType.Paragraph} variant={TypographyVariant.MediumRegular}>
          {lastPublishedText}
        </Typography>
      </EllipsisWithTooltipStyled>
      <BulletStyled />
      <EllipsisWithTooltipStyled tooltipText={lastPublishedDateText}>
        <Typography type={TypographyType.Paragraph} variant={TypographyVariant.MediumRegular}>
          {lastPublishedDateText}
        </Typography>
      </EllipsisWithTooltipStyled>
      <BulletStyled />
    </>
  ) : null;

  const lastChangesByText = `Last changes by ${lexiconMetadata?.lastUpdatedBy}`;
  const lastChangesByDateText = `at ${dateToText(lexiconMetadata?.updatedAt)}`;
  const lastUpdatedRow = shouldShowUpdate ? (
    <>
      <EllipsisWithTooltipStyled tooltipText={lastChangesByText}>
        <Typography type={TypographyType.Paragraph} variant={TypographyVariant.MediumRegular}>
          {lastChangesByText}
        </Typography>
      </EllipsisWithTooltipStyled>
      <BulletStyled />
      <EllipsisWithTooltipStyled tooltipText={lastChangesByDateText}>
        <Typography type={TypographyType.Paragraph} variant={TypographyVariant.MediumRegular}>
          {lastChangesByDateText}
        </Typography>
      </EllipsisWithTooltipStyled>
      <BulletStyled />
    </>
  ) : null;

  const subTitleRow =
    lastUpdatedRow || publishedRow ? (
      <LexiconPageHeaderSubTitleRowStyled>
        {lastUpdatedRow || publishedRow}
        <Link href='https://support.syte.ai/space/SI/2725183557/Insights+on+Syte%26%2339%3Bs+Deep+Tags#Thematic-tags'>
          How to Use the Lexicon Manager
          <Icon name={AvailableIcons.ArrowRec} />
        </Link>
      </LexiconPageHeaderSubTitleRowStyled>
    ) : null;

  const publishButtonIsEnabled = lexiconMetadata?.isPublished === false;

  const isAdminUser = userRole === UserRoles.SyteAdmin;

  return (
    <LexiconPageHeaderStyled>
      <LexiconPageHeaderTitleContainerStyled>
        <LexiconPageHeaderTitleRowStyled>
          <LexiconPageHeaderBackIconStyled
            name={AvailableIcons.ArrowRounded}
            onClick={onNavigateToListPage}
          />
          <LexiconPageMainHeaderTitle
            type={TypographyType.Paragraph}
            variant={TypographyVariant.LargeRegular}
          >
            Lexicon/
            {iconName && <FlagIcon name={iconName as AvailableIcons} />}
            <EllipsisWithTooltip tooltipText={displayName || ''}>{displayName}</EllipsisWithTooltip>
          </LexiconPageMainHeaderTitle>
          <LexiconPageHeaderTitleLocale>(Locale : {locale})</LexiconPageHeaderTitleLocale>
        </LexiconPageHeaderTitleRowStyled>
        {subTitleRow}
      </LexiconPageHeaderTitleContainerStyled>
      <LexiconPageHeaderActionContainerStyled>
        <Select type={SelectType.Menu} placeholder='Actions' value=''>
          <MenuItem onClick={onNavigateToCreateThematicTagPage} value='create-thematic-tag'>
            <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
              Create Thematic Tag
            </Typography>
          </MenuItem>
          <MenuItem onClick={onNavigateToCreateLexiconRulePage} value='create-lexicon-rule'>
            <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
              Create Rename Rule
            </Typography>
          </MenuItem>
          <MenuItem>
            <ActionsSwitchStyled checked={showApplyAll} onChange={onShowApplyOnChange}>
              <Switch.TitleTemplate>
                <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
                  Ask to rename all{' '}
                </Typography>
              </Switch.TitleTemplate>
            </ActionsSwitchStyled>
          </MenuItem>
          {isAdminUser && (
            <MenuItemWithIconStyled onClick={onNavigateToImportLexiconPage} className='import-file'>
              <Icon name={AvailableIcons.Import} /> Import
            </MenuItemWithIconStyled>
          )}
          <MenuItemWithIconStyled onClick={onNavigateToExportLexiconPage} className='export-file'>
            <Icon name={AvailableIcons.Export} /> Export
          </MenuItemWithIconStyled>
        </Select>

        <Button
          variant='primary'
          disabled={!publishButtonIsEnabled}
          loading={isInProgress}
          onClick={onPublishLexiconClick}
        >
          Publish changes
        </Button>
      </LexiconPageHeaderActionContainerStyled>
    </LexiconPageHeaderStyled>
  );
};
