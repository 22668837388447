import React, { useState } from 'react';
import { ExternalLink } from '../ExternalLink';
import {
  AvailableIcons,
  TabsV2,
  TabV2,
  Typography,
  TypographyType,
  TypographyVariant,
} from '../../../../components-dummy';
import { SummaryTab } from './SummaryTab';
import { AITagsTab } from './AITagsTab';
import { mapObjectEntries } from './mapObjectEntries';
import { Product } from '../../types/product';
import {
  CloseButtonStyled,
  ContentStyled,
  HeaderSeparatorStyled,
  HeaderStyled,
  ImagesContainerStyled,
  ImagesListStyled,
  ImagesTitleStyled,
  ImageStyled,
  ImageWrapperStyled,
  ProductDataContainerStyled,
  ScrollableSection,
  SubtitleWrapper,
  TabsContainerStyled,
  TitleStyled,
  TitleWrapper,
} from './ProductDetails.styled';

export enum ProductImages {
  imageUrl = 'Main Image',
  ctlImageUrl = 'Shop The Look Image',
  imageToIndex = 'High-Res Image',
  syteImages = 'All Images',
}

interface ProductDetailsProps {
  product: Product;
  onClose: VoidFunction;
}

export const ProductDetails = ({ product, onClose }: ProductDetailsProps) => {
  const { adUrl, images, details, skus, originalData, aiTags } = product;
  const { imageUrl, ctlImageUrl, imageToIndex, syteImages } = images;

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const onTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTabIndex(newValue);
  };

  const imagesCount =
    (!!imageUrl ? 1 : 0) + (!!ctlImageUrl ? 1 : 0) + (!!imageToIndex ? 1 : 0) + syteImages.length;
  const imagesCountText = `${imagesCount} ${imagesCount === 1 ? 'image' : 'images'}`;

  const imagesToDisplay = mapObjectEntries({
    obj: images,
    mapping: ProductImages,
  });

  return (
    <>
      <HeaderStyled>
        <TitleWrapper>
          <Typography type={TypographyType.Body} variant={TypographyVariant.MediumBold}>
            Product Details
          </Typography>
          <SubtitleWrapper>
            <TitleStyled type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
              {`SKU ${skus.sku}`}
            </TitleStyled>
            <HeaderSeparatorStyled>&#183;</HeaderSeparatorStyled>
            <ExternalLink showIcon onClick={() => window.open(adUrl, '_blank')}>
              Open Product Page
            </ExternalLink>
          </SubtitleWrapper>
        </TitleWrapper>
        <CloseButtonStyled name={AvailableIcons.Close} onClick={onClose} />
      </HeaderStyled>
      <ContentStyled>
        <ImagesContainerStyled>
          <ImagesTitleStyled type={TypographyType.Body} variant={TypographyVariant.SmallBold}>
            {imagesCountText}
          </ImagesTitleStyled>
          <ImagesListStyled>
            {imagesToDisplay.map(({ displayName, value }) => {
              const convertValueToArray = typeof value === 'string' ? [value] : value;
              return (
                <>
                  <ImagesTitleStyled
                    type={TypographyType.Body}
                    variant={TypographyVariant.SmallBold}
                  >
                    {displayName}
                  </ImagesTitleStyled>
                  {convertValueToArray.map(url => (
                    <ImageWrapperStyled key={url}>
                      <ImageStyled src={url} alt={displayName} loading='lazy' />
                    </ImageWrapperStyled>
                  ))}
                </>
              );
            })}
          </ImagesListStyled>
        </ImagesContainerStyled>
        <ProductDataContainerStyled>
          <TabsContainerStyled>
            <TabsV2 value={selectedTabIndex} onChange={onTabChange}>
              <TabV2
                label={
                  <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
                    Summary
                  </Typography>
                }
              />
              <TabV2
                label={
                  <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
                    Visual AI Tags
                  </Typography>
                }
                disabled={!Boolean(aiTags)}
              />
            </TabsV2>
          </TabsContainerStyled>
          <ScrollableSection>
            {selectedTabIndex === 0 && (
              <SummaryTab
                details={details}
                skus={skus}
                imagesToDisplay={imagesToDisplay}
                originalData={originalData}
              />
            )}
            {selectedTabIndex === 1 && aiTags && <AITagsTab aiTags={aiTags} />}
          </ScrollableSection>
        </ProductDataContainerStyled>
      </ContentStyled>
    </>
  );
};
