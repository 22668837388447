import React from 'react';
import {
  Accordion,
  AvailableIcons,
  Icon,
  Typography,
  TypographyType,
  TypographyVariant,
} from '../../../../components-dummy';
import { useCollapse } from '../../hooks/useCollapse';
import { mapObjectEntries } from './mapObjectEntries';
import {
  AccordionHeaderStyled,
  ProductDataGroupStyled,
  ProductDataStyled,
  TitleStyled,
} from './ProductDetails.styled';
import { AccordionTriggerGroup } from '../VisualEditor.styles';

const accordionId = 'product-identification';

export enum ProductIdentification {
  sku = 'SKU',
  sizeSku = 'Size SKU',
  colorSku = 'Color SKU',
  parentSku = 'Parent SKU',
}

interface Props {
  skus?: {
    sku?: string;
    sizeSku?: string;
    colorSku?: string;
    parentSku?: string;
  };
}

export const ProductIdentificationSection = ({ skus }: Props) => {
  const { expandedId, expand } = useCollapse(accordionId);

  const productIdentificationToDisplay = mapObjectEntries({
    obj: skus,
    mapping: ProductIdentification,
  });

  return (
    <Accordion expandedIds={expandedId} onSelectionChanged={expand}>
      <Accordion.Item id={accordionId} observeHeight>
        <Accordion.Item.Header>
          <AccordionHeaderStyled>
            <AccordionTriggerGroup>
              <TitleStyled type={TypographyType.Body} variant={TypographyVariant.SmallMedium}>
                Product Identification
              </TitleStyled>
              <Icon className={!expandedId ? 'closed' : ''} name={AvailableIcons.RightArrow} />
            </AccordionTriggerGroup>
          </AccordionHeaderStyled>
        </Accordion.Item.Header>
        <Accordion.Item.Content>
          <ProductDataGroupStyled>
            {productIdentificationToDisplay.map(({ displayName, value }) => {
              return (
                <ProductDataStyled key={displayName}>
                  <TitleStyled type={TypographyType.Body} variant={TypographyVariant.SmallMedium}>
                    {displayName}
                  </TitleStyled>
                  <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
                    {value}
                  </Typography>
                </ProductDataStyled>
              );
            })}
          </ProductDataGroupStyled>
        </Accordion.Item.Content>
      </Accordion.Item>
    </Accordion>
  );
};
