import React, { useCallback, useEffect, useRef, useState } from 'react';
import { NotificationType, Page } from 'src/components-dummy';
import {
  ICatalogProduct,
  IShopCatalog,
} from 'src/services/src/service/types/catalog-manager/catalog-manager';
import {} from 'src/components-bl/CatalogManager/CatalogManager.styles';
import { Dispatch } from 'src/components-bl/types';
import { PageStyled } from './CatalogExplorerPage.styles';
import { CatalogExplorerPageActions } from './CatalogExplorerPage.actions';
import { CatalogExplorerProductsView } from '../CatalogExplorerProductsView/CatalogExplorerProductsView';
import { ShopDataField } from 'src/services';
import { useManageDataFieldsForProduct } from './useManageDataFieldsForProduct';
import {
  DEFAULT_RESULTS_PER_PAGE_ENUM,
  OnPaginationChange,
} from 'src/components-dummy/PaginationToolBar';
import { CatalogExplorerPageHeader } from '../CatalogExplorerPageHeader/CatalogExplorerPageHeader';
import { MetaLanguage } from 'src/app-state-types';
import { isAbortError } from 'src/utils/error-helpers';
import { usePreviousState } from 'src/hooks';
import { debounce } from 'lodash';
import { CatalogExplorerFilters } from '../CatalogExplorerFilters/CatalogExplorerFilters';

export interface CatalogExplorerPageProps {
  catalog?: IShopCatalog;
  catalogProducts?: ICatalogProduct[];
  totalCatalogProducts?: number;
  totalFilteredProducts?: number;
  shopDataFields?: ShopDataField[];
  navigateToCatalogsList: () => void;
  catalogName: string;
  shopId: number;
  dispatch: Dispatch;
  languageMetadata?: MetaLanguage;
}

interface FiltersAndPaginationState {
  pagination: {
    skip: number;
    limit: number;
    totalItems: number;
  };
  filters: Record<string, string[] | number[]>;
  mainSearchFilterTerm?: string;
}

export const CatalogExplorerPage = ({
  catalog,
  catalogProducts,
  totalCatalogProducts,
  totalFilteredProducts,
  shopDataFields,
  navigateToCatalogsList,
  catalogName,
  shopId,
  dispatch,
  languageMetadata,
}: CatalogExplorerPageProps): JSX.Element => {
  const getProductsCancellationRef = useRef(null as AbortController | null);

  const [filtersAndPagination, setFiltersAndPagination] = useState<FiltersAndPaginationState>({
    filters: {},
    pagination: {
      skip: 0,
      totalItems: catalogProducts?.length || 0,
      limit: DEFAULT_RESULTS_PER_PAGE_ENUM[10] as number,
    },
    mainSearchFilterTerm: '',
  });

  const [isPendingOnFetchProducts, setIsPendingOnFetchProducts] = useState(false);
  const { productDataFieldsToFetch, dataFieldsMapByName } = useManageDataFieldsForProduct({
    shopDataFields,
    catalogName,
  });

  const prevProductDataFieldsToFetch = usePreviousState(productDataFieldsToFetch);

  const cancelGetProductsSignal = () => {
    if (!getProductsCancellationRef.current) {
      return;
    }

    if (getProductsCancellationRef.current.signal.aborted) {
      return;
    }

    try {
      getProductsCancellationRef.current.abort();
    } catch (error) {
      console.error(error);
    } finally {
      getProductsCancellationRef.current = null;
    }
  };

  const getProducts = useCallback(async () => {
    if (!productDataFieldsToFetch?.length) {
      return;
    }

    // Multi locale currently not supported
    if (catalog?.isMultiLocale) {
      navigateToCatalogsList();
      return;
    }

    setIsPendingOnFetchProducts(true);

    cancelGetProductsSignal();

    getProductsCancellationRef.current = new AbortController();

    let aborted = false;

    const cleanedFilters = Object.keys(filtersAndPagination.filters).reduce((prev, filterKey) => {
      const filterValues = filtersAndPagination.filters[filterKey];

      if (filterValues.length > 0) {
        prev[filterKey] = filterValues;
      }

      return prev;
    }, {});

    const thunkPromise: any = dispatch(
      CatalogExplorerPageActions.getProducts({
        shopId,
        catalogName,
        dataFields: productDataFieldsToFetch,
        pagination: {
          skip: filtersAndPagination.pagination.skip,
          limit: filtersAndPagination.pagination.limit,
        },
        filters: cleanedFilters,
        mainSearchFilterTerm: filtersAndPagination.mainSearchFilterTerm,
        cancellationSignal: getProductsCancellationRef.current.signal,
      })
    ) as any;

    thunkPromise
      .unwrap()
      .catch((apiError: any) => {
        aborted = isAbortError(apiError.error);

        if (!aborted) {
          console.error(apiError);
          dispatch(
            CatalogExplorerPageActions.notification({
              type: NotificationType.Error,
              customMessage: 'Failed to fetch catalog products',
            })
          );
        }
      })
      .finally(() => {
        if (!aborted || prevProductDataFieldsToFetch !== productDataFieldsToFetch) {
          setIsPendingOnFetchProducts(false);
        }
      });
  }, [
    filtersAndPagination.pagination.skip,
    filtersAndPagination.pagination.limit,
    filtersAndPagination.filters,
    filtersAndPagination.mainSearchFilterTerm,
    productDataFieldsToFetch,
    catalog?.isMultiLocale,
    shopId,
    catalogName,
  ]);

  const debouncedGetProducts = useCallback(debounce(getProducts, 300), [getProducts]);

  useEffect(() => {
    if (!shopId || !catalogName) {
      return;
    }

    debouncedGetProducts();

    return () => {
      debouncedGetProducts.cancel();
    };
  }, [catalogName, getProducts, shopId]);

  useEffect(() => {
    return () => {
      cancelGetProductsSignal();
      dispatch(CatalogExplorerPageActions.clearCatalogExplorerData());
    };
  }, []);

  const onPaginationChange: OnPaginationChange = useCallback(newState => {
    setFiltersAndPagination(prevState => {
      if (
        prevState.pagination.skip !== newState.skip ||
        prevState.pagination.limit !== newState.limit
      ) {
        setIsPendingOnFetchProducts(true);
      }

      return {
        ...prevState,
        pagination: { ...prevState.pagination, ...newState },
      };
    });
  }, []);

  /**
   * Dynamic filters by field name.
   */
  const onFiltersChange = useCallback((partialUpdate: any): void => {
    setFiltersAndPagination(prevState => {
      const hasChanges = Object.keys(partialUpdate).some(filterKey => {
        const updateFilterValues = partialUpdate[filterKey];
        const prevFilterValues = prevState.filters[filterKey] || [];

        return updateFilterValues !== prevFilterValues;
      });

      if (hasChanges) {
        return {
          ...prevState,
          pagination: { ...prevState.pagination, skip: 0 },
          filters: { ...prevState.filters, ...partialUpdate },
        };
      }

      return prevState;
    });
  }, []);

  const onMainSearchFilterChange = useCallback(
    debounce((value: string) => {
      setFiltersAndPagination(prevState => {
        return {
          ...prevState,
          pagination: {
            ...prevState.pagination,
            skip: 0,
          },
          mainSearchFilterTerm: value,
        };
      });
    }, 350),
    []
  );

  const onClearAllFilters = useCallback(() => {
    setFiltersAndPagination(prevState => {
      return {
        ...prevState,
        pagination: {
          ...prevState.pagination,
          skip: 0,
        },
        filters: {},
        mainSearchFilterTerm: '',
      };
    });
  }, []);

  const locale = catalog?.defaultLocale;

  return (
    <PageStyled>
      <Page.Header>
        <CatalogExplorerPageHeader
          catalogName={catalogName}
          catalog={catalog}
          totalCatalogProducts={totalCatalogProducts}
          navigateToCatalogsList={navigateToCatalogsList}
          languageMetadata={languageMetadata}
        />
      </Page.Header>
      <Page.Content>
        <CatalogExplorerFilters
          onFiltersStateChange={onFiltersChange}
          onMainSearchFilterChange={onMainSearchFilterChange}
          onClearAllFilters={onClearAllFilters}
          shopId={shopId}
          catalogName={catalogName}
          dataFieldsMapByName={dataFieldsMapByName}
          filters={filtersAndPagination.filters}
          mainSearchFilterTerm={filtersAndPagination.mainSearchFilterTerm}
          totalFilteredProducts={totalFilteredProducts || 0}
          locale={locale}
          dispatch={dispatch}
        />
        <CatalogExplorerProductsView
          catalogProducts={catalogProducts}
          totalCatalogProducts={totalFilteredProducts}
          shopId={shopId}
          isPendingOnFetchProducts={isPendingOnFetchProducts}
          dataFieldsMapByName={dataFieldsMapByName}
          paginationState={filtersAndPagination.pagination}
          locale={locale}
          onPaginationChange={onPaginationChange}
        />
      </Page.Content>
    </PageStyled>
  );
};
