import React from 'react';
import {
  Accordion,
  AvailableIcons,
  Icon,
  TypographyType,
  TypographyVariant,
} from '../../../../components-dummy';
import { useCollapse } from '../../hooks/useCollapse';
import { ValueToDisplayByDataType } from './ValueToDisplayByDataType';
import {
  AccordionHeaderStyled,
  ProductDataGroupStyled,
  ProductDataStyled,
  TitleStyled,
} from './ProductDetails.styled';
import { AccordionTriggerGroup } from '../VisualEditor.styles';

const accordionId = 'product-images';

interface Props {
  imagesToDisplay?: { displayName: string; value: string | string[] }[];
}

export const ProductImagesSection = ({ imagesToDisplay }: Props) => {
  const { expandedId, expand } = useCollapse(accordionId);

  return (
    <Accordion expandedIds={expandedId} onSelectionChanged={expand}>
      <Accordion.Item id={accordionId} observeHeight>
        <Accordion.Item.Header>
          <AccordionHeaderStyled>
            <AccordionTriggerGroup>
              <TitleStyled type={TypographyType.Body} variant={TypographyVariant.SmallMedium}>
                Product Images
              </TitleStyled>
              <Icon className={!expandedId ? 'closed' : ''} name={AvailableIcons.RightArrow} />
            </AccordionTriggerGroup>
          </AccordionHeaderStyled>
        </Accordion.Item.Header>
        <Accordion.Item.Content>
          <ProductDataGroupStyled>
            {imagesToDisplay.map(({ displayName, value }) => {
              return (
                <ProductDataStyled key={displayName}>
                  <TitleStyled type={TypographyType.Body} variant={TypographyVariant.SmallMedium}>
                    {displayName}
                  </TitleStyled>
                  <ValueToDisplayByDataType value={value} />
                </ProductDataStyled>
              );
            })}
          </ProductDataGroupStyled>
        </Accordion.Item.Content>
      </Accordion.Item>
    </Accordion>
  );
};
